import {BaseService} from "@/core/services/BaseService";
import {generateString, generatorQueryString} from "@/core/helpers/functions";

class TaskService extends BaseService<any> {
  endpoint = '/task';

  async pick(id) {
    return await this.http().get(this.endpoint + "/" + id + "/pick").then(res => {
      return res.data
    })
  }

  async start(id) {
    return await this.http().get(this.endpoint + "/" + id + "/start-progress").then(res => {
      return res.data
    })
  }

  async complete(id, results) {
    return await this.http().put(this.endpoint + "/" + id + "/complete", results).then(res => {
      return res.data
    })
  }

  async approve(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/approve", data).then(res => {
      return res.data
    })
  }

  async calendar(filter): Promise<any> {
    const queryString = generateString(filter);
    return this.http().get<any>(this.endpoint + "/calendar?" + queryString).then(res => {
      return res.data
    })
  }
}

export default new TaskService();